export const state = () => ({
  pensions: null,
})

export const actions = {
  async create({ commit }, { contactId, data }) {
    data = formatDates(this.$dateFns, data)
    data = replaceUndefinedWithNull(data)

    const pension = await this.$axios.$post(
      `contacts/${contactId}/pensions`,
      data
    )
    return pension.data
  },

  async update({ dispatch }, { id, data }) {
    data = formatDates(this.$dateFns, data)
    data = replaceUndefinedWithNull(data)

    const pension = await this.$axios.$patch(`/pensions/${id}`, data)

    return pension.data
  },

  async destroy({ commit }, { id }) {
    await this.$axios.$delete(`/pensions/${id}`)
  },
}

export const mutations = {
  setTranches(state, value) {
    state.tranches = value
  },
}

export const getters = {}

function formatDates(dateFns, data) {
  const dateFields = [
    'due_on',
    'valid_from',
    'commission_expected_at',
    'canceled_at',
  ]

  dateFields.forEach((field) => {
    data[field] = data[field] ? dateFns.format(data[field], 'yyyy-MM-dd') : null
  })

  return data
}

function replaceUndefinedWithNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null
    }
  })

  return data
}
