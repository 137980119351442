import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome'

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleRight,
  faBars,
  faBell,
  faBookOpen,
  faCaretLeft,
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFileInvoiceDollar,
  faFileUpload,
  faFolderOpen,
  faHandshake,
  faHeartBroken,
  faHome,
  faInfoCircle,
  faMinus,
  faPaperPlane,
  faPencilAlt,
  faPercent,
  faPizzaSlice,
  faPlus,
  faQuestionCircle,
  faRocket,
  faSave,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStream,
  faThumbsUp,
  faTimes,
  faTrashAlt,
  faUniversity,
  faUser,
  faUserAltSlash,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUserTie,
  faExternalLinkAlt,
  faEnvelope,
  faChevronDown,
  faArchive,
  faSync,
  faPhone,
  faCheckCircle,
  faMinusCircle,
  faEquals,
  faNotEqual,
  faGreaterThanEqual,
  faLessThanEqual,
  faUmbrella,
  faLock,
  faLockOpen,
} from '@fortawesome/free-solid-svg-icons'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import {
  faAlarmSnooze,
  faConstruction,
  faBan,
  faRepeat,
  faSparkles,
} from '@fortawesome/pro-regular-svg-icons'

config.autoAddCss = false

library.add(
  faAlarmSnooze,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleRight,
  faBars,
  faBell,
  faBookOpen,
  faCaretLeft,
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFileInvoiceDollar,
  faFileUpload,
  faFolderOpen,
  faHandshake,
  faHeartBroken,
  faHome,
  faInfoCircle,
  faMinus,
  faPaperPlane,
  faPencilAlt,
  faPercent,
  faPizzaSlice,
  faPlus,
  faQuestionCircle,
  faRocket,
  faSave,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStream,
  faThumbsUp,
  faTimes,
  faTrashAlt,
  faUniversity,
  faUser,
  faUserAltSlash,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUserTie,
  faWindows,
  faConstruction,
  faExternalLinkAlt,
  faEnvelope,
  faChevronDown,
  faArchive,
  faSync,
  faBan,
  faPhone,
  faEnvelope,
  faRepeat,
  faSparkles,
  faCheckCircle,
  faMinusCircle,
  faEquals,
  faNotEqual,
  faGreaterThanEqual,
  faLessThanEqual,
  faUmbrella,
  faLock,
  faLockOpen
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
